import React, { useState, useEffect } from "react";
import  { registerLocale } from "react-datepicker";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Box,
  extendTheme,
  useDisclosure,

} from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import SideBar from "../../components/SideBar";

// import { BreadCrumbs } from "../../components/Breadcrumb2";
import InternalHeader from "../../components/InternalHeader";
// import PaletaExames from "../../components/paletaExames.jsx";

import GridAuthorization from "../../components/GridAuthorization";
import GridAppointment from "../../components/GridAppointment";

import GridAttendance from "../../components/GridAttendance";

// import Search from "../../images/search.svg";
// import ErrorStatus from "../../images/errorStatus.svg";
// import TextSelectHospital from "../../components/TextSelectHospital";

// import ModalNotification from "../../components/ModalNotification";
// import ModalAlert from "../../components/ModalAlert";
import ModalSelectHospital from "../../components/ModalSelectHospital";

import { useAuth, useExams, useHospitals, useUsers } from "../../hooks";


const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

extendTheme({ breakpoints });

function Dashboard() {
  //const navigate = useNavigate();
  registerLocale("ptBR", ptBR);

  const { 
    listAppointment,
    listAuthorization
   } = useExams();
  

  const { user } = useAuth();
  const { getHospitalsList, getUserHospitalList, getHospitalNotification } = useHospitals();
  const { getUser } = useUsers();


  const [showAppointment, setShowAppointment] = useState(null);
  const [showAuthorization, setShowAuthorization] = useState(null);

  const [showDashboard, setShowDashboard] = useState(null);
  
  const [userLogged, setUserLogged] = useState();

  const { onClose } = useDisclosure();

  //const [firstTime, setFirstTime] = useState(false);

  const [uuidUser, setUUIDUser] = useState(
    // localStorage.getItem("hospital") ? localStorage.getItem("hospital") : null
  );
  const [hospitalName, setHospitalName] = useState("");

  const itemsPerPage = 3;

  const [pageCountAppointment, setPageCountAppointment] = useState(0);
  const [pageCountAuthorization, setPageCountAuthorization] = useState(0);
  
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageAppointment, setCurrentPageAppointment] = useState(0);
  const [currentPageAuthorization, setCurrentPageAuthorization] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [widthScreen, setWidthScreen] = useState(0);

  const [uuidHopistal, setUuidHospital] = useState(false);
  const [hospitalsListNoPagination, setHospitalsListNoPagination] = useState(
    [],
  );

  const [notificationList, setNotificationList] = useState([]);

  // const [openModalHospital, setOpenModalHospital] = useState(false);
  
  const [permissions, setPermissions] = useState();

  
  const handleChildClick = () => {
    getAppointmentList();
  };

  const handleOnChangeHospital = (uuidChanged) => {
    setUUIDUser(uuidChanged);

    const { permissions } = hospitalsListNoPagination.find((hospital) => hospital.uuid === uuidChanged);

    setPermissions(permissions);

    localStorage.setItem("hospital", uuidChanged);
    localStorage.setItem("hospitalName", hospitalsListNoPagination.find((hospital) => hospital.uuid === uuidChanged).name);
    localStorage.setItem("permissions", permissions);

    // localStorage.setItem("permissions", JSON.stringify(hospitalsListNoPagination.find((hospital) => hospital.uuid === uuidChanged)));

    setHospitalName(hospitalsListNoPagination.find((hospital) => hospital.uuid === uuidChanged).name);

  };

  async function getUserLogged() {

    // if (!user) {
      try {
        const result = await getUser(user.id);
        setUserLogged(result.users);
      } catch (error) {
        
        console.error(error);
      }
    // } else {
    //   setUserLogged(user);
    // }
  }

  async function listHospitalNotification() {

    setNotificationList(await getHospitalNotification());

  }


  async function getAuthorizationList() {
    
    if (!uuidUser) {
      return;
    }
    try {

      const params = {
        pageNo: currentPageAuthorization ? currentPageAuthorization + 1 : 1,
        pageSize: itemsPerPage,
        Order: "DESC",
      };

      const response = await listAuthorization(uuidUser,user.role_id, params);
      setShowAuthorization(response?.Authorization?.[0]?.authorization_list);
      setPageCountAuthorization(response?.Authorization[0]?.total_pages);

    } catch (error) {
      console.error(error);
    }
  }


  async function getAppointmentList() {
    if (!uuidUser) {
      return;
    }

    try {

      const params = {
        pageNo: currentPageAppointment ? currentPageAppointment + 1 : 1,
        pageSize: itemsPerPage,
        Order: "DESC",
      };

      const response = await listAppointment(uuidUser,user.role_id, params);

      setShowAppointment(response?.Appointment?.[0]?.appointment_list);
      setPageCountAppointment(response?.Appointment[0]?.total_pages);   

      // console.log("response?.Appointment[0]?.total_pages", response)

    } catch (error) {
      console.error(error);
    }
  }

  async function getHospitalList() {
    
    const hospital = await getHospitalsList();

    const hospitalList = hospital.map((item) => ({
      uuid: item.uuid,
      name: item.name,
    }));
  
    setHospitalsListNoPagination(hospitalList);
    
  }

  async function getUserHospitalsList() {

      const response = await getUserHospitalList();

      const { hospitals } = response;
      setHospitalsListNoPagination(hospitals);

      return hospitals;
  }

  function getDate() {
    if (user?.role_id === 2) {
      if(user?.hospitals.length === 1){
        setUUIDUser(user?.hospitals[0].uuid);
      }      
    }    
    if (user?.role_id === 1) {
      setUUIDUser(uuidHopistal);
    }
    if (user?.role_id === 3) {
      setUUIDUser(user?.hash);
    }
    if (user?.role_id === 4) {
      setUUIDUser(user?.uuid_doc);
    }
  }

  async function getUserPermission( defaultHospital ) {

    const hospitals = await getUserHospitalsList();    
    const { permissions } = hospitals.find((hospital) => hospital.uuid === defaultHospital);

    setPermissions(permissions);

  }


  useEffect(() => {
  
 
    if (
      user?.cod_pf ||
      user?.hash ||
      user?.hospitals?.[0]?.uuid ||
      uuidHopistal ||
      user?.uuid_doc
    ) {
      getDate();
    }

    if (user?.role_id === 1) {
      getHospitalList();
      // console.log("setHospitalsListNoPagination", hospitalsListNoPagination)
    }

    if (user?.role_id === 2) {
      getUserHospitalsList();

    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    getUserLogged();
    getAuthorizationList();

    if (uuidUser !== undefined) {

      setShowDashboard(true);

    }

  },[uuidUser])

  useEffect(() => {

    if (uuidUser === undefined && localStorage.getItem("hospital") && (user.role_id !== 1) ) {      

      localStorage.getItem("hospital").length > 10 ? setUUIDUser(localStorage.getItem("hospital")) : setUUIDUser(null);
      localStorage.getItem("hospitalName") ? setHospitalName(localStorage.getItem("hospitalName")) : setHospitalName(null);
      getUserPermission(localStorage.getItem("hospital"));
    }

    

    if (user.role_id === 1 && permissions === undefined) {
      setPermissions([1,2,3]);
    }

    if (user.role_id === 3) {
      setHospitalName("Paciente")
    }
    if (user.role_id === 4) {
      setHospitalName("Médico")
    }
    getUserLogged();
    listHospitalNotification();

  }, []) 

   
  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="220px" id="sidebar" className="sidebar">
        <SideBar permissions={permissions} />
      </Flex>

      <Box w="85%" p="24px 88px 22px 24px">
        <Flex alignContent="center" alignItems="center">
          <InternalHeader 
            user={user}
            // showHospital={true}
            uuidUser={uuidUser ? uuidUser : localStorage.getItem("hospital")}
            hospitalName={hospitalName ? hospitalName : localStorage.getItem("hospitalName")}
          />
        </Flex>

        {

          hospitalsListNoPagination && hospitalsListNoPagination.length > 0 && (

            <ModalSelectHospital
              hospitalList={hospitalsListNoPagination}
              handleOnChangeHospital={handleOnChangeHospital}
              isOpen={hospitalsListNoPagination.length && uuidUser === undefined}
              onClose={onClose}
            />
          )

        }


        {
          showDashboard && (

            <Box
            m="18px 0"
            p="10px 20px"
            border="1px solid #CED4DA"
            borderRadius="10px"
            backgroundColor="var(--cinza-bg)"
          >
            <Tabs variant='soft-rounded' colorScheme='green'>
              <TabList>
                { (
                    (user?.role_id === 1) 
                    || permissions?.find(item => item === 1) 
                    || permissions?.find(item => item === 2)
                    // || (user?.permissoes.findIndex(item => item.id.toString() === '1')) >= 0
                    // || (user?.permissoes.findIndex(item => item.id.toString() === '2')) >= 0
  
                  )                  
                    &&
  
                      <Tab 
                        textColor="#F3953F" 
                        fontWeight={800} 
                        id="tabAgendamento">Agendamento</Tab>
  
                }
  
                <Tab textColor="#F3953F" fontWeight={800} id="tabAutorizacao">Autorização</Tab>
  
                {
                  (
                    user?.role_id === 1 || user?.role_id === 3 

                    || permissions?.find(item => item === 1)
                    || permissions?.find(item => item === 3)
                    // ||
                    
                    // user?.permissoes.findIndex(item => item.id.toString() === '1') >= 0  ||
                    // user?.permissoes.findIndex(item => item.id.toString() === '3') >= 0 
                  )
                  &&
                  <Tab textColor="#F3953F" fontWeight={800} id="tabResultado">Resultado</Tab>              
                }
                
              </TabList>
              <TabPanels>
    
                {
                  (
                    (user?.role_id === 1 ) 
                    || permissions?.find(item => item === 1)
                    || permissions?.find(item => item === 2)
                    // || (user?.permissoes.findIndex(item => item.id.toString() === '1')) >= 0
                    // || (user?.permissoes.findIndex(item => item.id.toString() === '2')) >= 0
  
                  )      
                  &&
                  <TabPanel>
                    <GridAppointment 
                      showAppointment={showAppointment} 
                      pageCountAppointment={pageCountAppointment}
                      currentPageAppointment={currentPageAppointment}
                      setCurrentPageAppointment={setCurrentPageAppointment}  
                      onChildClick ={handleChildClick}
                    /> 
                  </TabPanel>
                }
                
                <TabPanel>
                  <GridAuthorization 
                    showAuthorization={showAuthorization} 
                    pageCountAuthorization={pageCountAuthorization}
                    currentPageAuthorization={currentPageAuthorization}
                    setCurrentPageAuthorization={setCurrentPageAuthorization}
                  />
                </TabPanel>
                  {
                    (
                      (user?.role_id === 1 || user?.role_id === 3) 
                      || permissions?.find(item => item === 1)
                      || permissions?.find(item => item === 3)
       
                    )
  
                    &&
                      <TabPanel>
                        <GridAttendance 
                          uuidSelected={uuidUser}
                          itemsPage={6}
                        />
                      </TabPanel>
                
                  }
                
              </TabPanels>
            
            
            </Tabs>
          </Box>

            
          )

        }


      </Box>


      {/* {

        (user.role_id === 2 && notificationList.length > 0 && uuidUser)   && (

          <ModalNotification 
            notificationList={notificationList} 
            onClose={onClose} 
            isOpen={isOpen} 
            
          />


        )

      } */}

      {/* <ModalAlert 
        isOpen={isOpen} 
        onClose={onClose}
      /> */}

    </Flex>
  );
}

export default Dashboard;
