import React from "react";
import api_public from "../services/api_public";
import { toast } from "react-toastify";

export const RegisterContext = React.createContext({});

export const RegisterProvider = ({ children }) => {
  const createPatient = async (data) => {
    try {
      const response = await api_public.post(`patient/register`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const createDoctor = async (data) => {
    try {
      const response = await api_public.post(`doctor/register`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      console.error(error.response?.data?.message);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const createUserHospital = async (data) => {
    try {
      const response = await api_public.post(`user/hospital/register`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }

      return error;
    }
  };
  const createPartner = async (data) => {
    try {
      const response = await api_public.post(`partner/register`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const speciality = async () => {
    try {
      const response = await api_public.get(`doctor/speciality`);
      return response;
    } catch (error) {}
  };

  const hospitalsList = async () => {
    try {
      const response = await api_public.get(`hospitals/list`);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <RegisterContext.Provider
      value={{
        createPatient,
        createDoctor,
        speciality,
        createPartner,
        createUserHospital,
        hospitalsList,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};
