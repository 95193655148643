import { useState } from "react";
import { Flex, Text, Img } from "@chakra-ui/react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextInput from "../../../components/TextInput";
import Terms from "../../../components/Term.jsx";

import AgrePolicy from "../../../images/Política de Privacidade_PortalSenne.pdf";
import AgreTerms from "../../../images/Termo de Uso_Portal Senne.pdf";
import Paciente from "../../../images/cliente.svg";
import Loading from "../../../components/Loading";

import { useRegister } from "../../../hooks";

const schema = yup.object().shape({
  email: yup.string().email().trim().required("O campo email é obrigatório!"),
  phone: yup.string().when("1", {
    is: "2",
    then: yup.string().trim().required("O campo telefone é obrigatório!"),
  }),
  cellphone: yup.string().when("2", {
    is: "1",
    then: yup.string().trim().required("O campo telefone é obrigatório!"),
  }),
  policy: yup
    .boolean()
    .required("leia e aceite os termos antes de prosseguir.")
    .oneOf([true], "Leia e aceite os termos antes de prosseguir."),
  termsOfNotice: yup.boolean(),
  termsOfContact: yup.boolean(),
});
export default function PatientRegister() {
  const { createPatient } = useRegister();

  const [loading, setLoading] = useState(false);
  // const [PhoneNumber, setPhoneNumber] = useState("1");

  const { token } = useLocation()["state"];

  const navigate = useNavigate();

  async function submit(values) {
    try {
      setLoading(true);

      const { email, cellphone, phone, policy, termsOfNotice, termsOfContact } =
        values;

      const params = {
        token,
        email,
        cellphone,
        phone,
        policy,
        termsOfNotice,
        termsOfContact,
      };

      const response = await createPatient(params);

      if (response.status === 200) {
        navigate("/cadastro-aprovado");
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function openpdf(item) {
    window.open(item, "_blank");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <TemplateRegister>
          {loading && <Loading />}
          <Content>
            <Flex
              flexDirection="column"
              backgroundColor="white"
              p="20px 46px"
              borderRadius="8px"
              w="100%"
            >
              <Flex
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                mb="77px"
                gap="10px"
              >
                <Flex w="100%" justifyContent="center" gap="1.5em">
                  <Img w="42px" src={Paciente} alt="" />
                  <Text
                    w="150px"
                    as="strong"
                    color="var(--orange-senne-secundary)"
                  >
                    Paciente ou <br />
                    Responsável
                  </Text>
                </Flex>
                <h1 fontSize="40px">Faça seu Cadastro</h1>
              </Flex>

              <Flex gap="10px" justifyContent="center" flexDirection="column">
                <TextInput
                  isError={errors.email}
                  register={{ ...register("email") }}
                  InputPlaceholder="Digite seu Email"
                  labelDescription="Email"
                  name="email"
                  id="email"
                />



                <TextInput
                  isError={errors.cellphone}
                  register={{ ...register("cellphone") }}
                  labelDescription="Celular"
                  InputPlaceholder="Qual é o seu celular"
                  maskInput="(99) 99999-9999"
                  name="cellphone"
                  id="cellphone"
                />

                <Terms
                  register={{ ...register("policy") }}
                  isError={errors.policy}
                >
                  <Text p="0">
                    Li e aceito a
                    <Link to="" onClick={() => openpdf(AgrePolicy)}>
                      <Text as="strong"> Política de Privacidade </Text>
                    </Link>
                    e os
                    <Link to="" onClick={() => openpdf(AgreTerms)}>
                      <Text as="strong"> Termos de uso</Text>
                    </Link>
                    .
                  </Text>
                </Terms>
              </Flex>

              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="90%"
              >
                <Terms
                  register={{ ...register("termsOfNotice") }}
                  isError={errors.termsOfNotice}
                >
                  <Text p="0">
                    Deseja receber notícias e informações nos canais de
                    comunicação?
                  </Text>
                </Terms>
              </Flex>
              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="90%"
              >
                <Terms
                  register={{ ...register("termsOfContact") }}
                  isError={errors.termsOfContact}
                >
                  <Text p="0">
                    Você permite que a nossa equipe entre em contato para a
                    discussão de caso e informe de resultados alterados?
                  </Text>
                </Terms>
              </Flex>
              <Flex
                mt="50px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/">
                  <GoBackButton />
                </Link>

                <RegisterButton type="submit">Continuar</RegisterButton>
              </Flex>
            </Flex>
          </Content>
        </TemplateRegister>
      </form>
    </>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
