import axios from "axios";

const api = axios.create({
  // baseURL: 'https://prod.mageda.com.br/api'     // Servidor Produção
  // baseURL: "http://cloud-api.senneliquor.com.br:8000/api", // Servidor Teste
  // baseURL: "http://localhost:8000/api", // Local
  baseURL: "https://api-hom.senneliquor.com.br/api",
  //baseURL: "https://api-prod.senneliquor.com.br/api",
 // baseURL: process.env.baseURL,
  // baseURL: "https://api-node-hom.proudground-c683b123.brazilsouth.azurecontainerapps.io/api", 

 timeout: 1000*30,
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("Auth_senne");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  //config.headers["Access-Control-Allow-Origin"] = "*";
  //config.headers["Access-Control-Allow-Methods"]= "POST, PUT, PATCH, GET, DELETE, OPTIONS";
  return config;
});

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error?.response?.status === 401) {
      // window.location.pathname = "/";
    }

    return Promise.reject(error);
  },
);

export default api;
