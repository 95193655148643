import { useState, useEffect } from "react";
import { Flex, Text, Img } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import HospitalStepTwo from "./hospitalStepTwo";
import FirstLetter from "../../../components/FirstLetter";
import TextSelect from "../../../components/TextSelect";
import TextInput from "../../../components/TextInput";

import { useRegister } from "../../../hooks";

import Hospital from "../../../images/labOrange.svg";

const schema = yup.object().shape({
  name: yup.string().required("O campo nome é obrigatorio"),
  cpf: yup
    .string()
    .trim()
    .required("Campo obrigatório!")
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "O campo documento é inválido"),
  hospitals: yup.string().required("Selecione um hospital"),
});
export default function RegisterHospitalStepOne() {
  const [steps, setSteps] = useState(0);
  const [props, setProps] = useState({});
  const [hospitals, setHospitals] = useState();

  const { hospitalsList } = useRegister();

  async function getHospitals() {
    try {
      const response = await hospitalsList();
      setHospitals(response?.data);
    } catch (error) {
      toast.error("Não foi possível carregar a lista de especialidades");
      console.error(error);
    }
  }
  useEffect(() => {
    getHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(values) {
    const values_API = {
      ...values,
      name: FirstLetter(values.name),
      hospitals: [Number(values.hospitals)],
    };
    setProps(values_API);
    setSteps(1);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  if (steps === 0) {
    return (
      <TemplateRegister>
        <Content>
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="20px 46px"
            borderRadius="8px"
            w="100%"
          >
            <form onSubmit={handleSubmit(submit)}>
              <Flex
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                mb="42px"
                gap="10px"
              >
                <Flex
                  w="100%"
                  justifyContent="center"
                  gap="1.5em"
                  alignItems="center"
                >
                  <Img w="42px" src={Hospital} alt="" />
                  <Text
                    w="150px"
                    as="strong"
                    color="var(--orange-senne-secundary)"
                  >
                    Hospitais
                  </Text>
                </Flex>
                <h1 fontSize="40px">Faça seu Cadastro</h1>
              </Flex>

              <Flex gap="10px" justifyContent="center" flexDirection="column">
                <TextInput
                  isError={errors.name}
                  register={{ ...register("name") }}
                  labelDescription="Nome Completo"
                  InputPlaceholder="Digite o nome"
                  name="name"
                  id="name"
                />
                <TextInput
                  isError={errors.cpf}
                  register={{ ...register("cpf") }}
                  labelDescription="CPF"
                  InputPlaceholder="Digite seu CPF"
                  maskInput="***.***.***-**"
                  name="cpf"
                  id="cpf"
                />
                <TextSelect
                  isError={errors.hospitals}
                  register={{ ...register("hospitals") }}
                  labelDescription="Hospitais"
                  name="hospitals"
                  id="hospitals"
                  titleOption="Escolha uma unidade"
                  options={hospitals}
                  pb="10px"
                />
              </Flex>

              <Flex
                mt="50px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/cadastro">
                  <GoBackButton />
                </Link>

                <RegisterButton type="submit">Continuar</RegisterButton>
              </Flex>
            </form>
          </Flex>
        </Content>
      </TemplateRegister>
    );
  }

  return <HospitalStepTwo props={props} />;
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
